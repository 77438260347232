import React, { useEffect, useState, useRef } from "react";
import "firebase/firestore";
import BondsTable from "../Bonds/BondsTable";
import TermsTable from "../FixedTerm/TermTable";
import IposTable from "../IPOs/IposTable";
import "./style.css";
import {
  displayIposTable,
  formatNumber,
  getAuthUser,
  getBondsHoldings,
  getCashDeposit,
  getStockFromUserDB,
  getUserFixedTerm,
  getUserIpos,
} from "../../firebase/firestore";
import { BsCardChecklist } from "react-icons/bs";
import { CiMoneyBill, CiMoneyCheck1 } from "react-icons/ci";
import { PiBriefcase } from "react-icons/pi";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import StocksTable from "../StockTrading/StockTable";

export default function MyAccounts() {
  const [totalBondAmount, setTotalBondAmount] = useState(0);
  const [totalTermAmount, setTotalTermAmount] = useState(0);
  const [totalIpoAmount, setTotalIpoAmount] = useState(0);
  const [totalShares, setTotalShares] = useState(0);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isIposVisible, setIsIposVisible] = useState(false);
  const [bonds, setBonds] = useState([]);
  const [terms, setTerms] = useState([]);
  const [ipos, setIpos] = useState([]);
  const [cashDeposits, setCashDeposits] = useState([]);
  const [shares, setShares] = useState([]);

  const [activeTab, setActiveTab] = useState("bonds");
  const bondsRef = useRef(null);
  const termsRef = useRef(null);
  const iposRef = useRef(null);
  const stockRef = useRef(null);
  const depositRef = useRef(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});

  useEffect(() => {
    const unsubscribeIpos = displayIposTable(setIsIposVisible);
    return () => {
      unsubscribeIpos();
    };
  }, []);

  useEffect(() => {
    const activeRef =
      activeTab === "bonds"
        ? bondsRef
        : activeTab === "terms"
        ? termsRef
        : activeTab === "ipos"
        ? iposRef
        : stockRef;

    if (activeRef.current) {
      const width = activeRef.current.offsetWidth;
      const left = activeRef.current.offsetLeft;
      setIndicatorStyle({ width, left });
    }
  }, [activeTab]);

  // Convert a string representation of a number to a JavaScript number
  function convertToNumber(stringAmount) {
    // Check if the input is already a number, and if not, convert it
    if (typeof stringAmount === "string") {
      return parseFloat(stringAmount.replace(",", ""));
    } else if (typeof stringAmount === "number") {
      return stringAmount;
    }
    return 0;
  }

  useEffect(() => {
    const user = getAuthUser();
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Reference to Firestore collections
    const bondsCollection = collection(db, "user", user, "bondHoldings");
    const termsCollection = collection(db, "user", user, "fixedTermDeposits");
    const iposCollection = collection(db, "user", user, "ipos");
    const cashDepositCollection = collection(db, "user", user, "cashDeposits");
    const stocksCollection = collection(db, "user", user, "stocks");

    // Real-time updates for cash deposits
    const unsubscribeCashDeposits = onSnapshot(
      cashDepositCollection,
      async (snapshot) => {
        const updatedCashDeposit = snapshot.docs.map((doc) => doc.data());
        const totalCashDepositAmount =
          await fetchAndCalculateTotalCashDepositAmount(
            user,
            updatedCashDeposit
          );

        // Ensure state is updated based on the calculated value
        setCashDeposits(updatedCashDeposit);
        setTotalDeposits(totalCashDepositAmount);
      }
    );

    // Real-time updates for bonds
    const unsubscribeBonds = onSnapshot(bondsCollection, async (snapshot) => {
      const updatedBonds = snapshot.docs.map((doc) => doc.data());
      setBonds(updatedBonds);
      const totalBondAmount = await fetchAndCalculateTotalBondAmount(
        user,
        updatedBonds
      );
      setTotalBondAmount(totalBondAmount);
    });

    // Real-time updates for terms
    const unsubscribeTerms = onSnapshot(termsCollection, async (snapshot) => {
      const updatedTerms = snapshot.docs.map((doc) => doc.data());
      setTerms(updatedTerms);
      const totalTermAmount = await fetchAndCalculateTotalTermAmount(
        user,
        updatedTerms
      );
      setTotalTermAmount(totalTermAmount);
    });

    // Real-time updates for ipos
    const unsubscribeIpos = onSnapshot(iposCollection, async (snapshot) => {
      const updatedIpos = snapshot.docs.map((doc) => doc.data());
      setIpos(updatedIpos);
      const totalIpoAmount = await fetchAndCalculateTotalIpoAmount(
        user,
        updatedIpos
      );
      setTotalIpoAmount(totalIpoAmount);
    });

    // Real-time updates for shares
    const unsubscribeShares = onSnapshot(stocksCollection, async (snapshot) => {
      const updatedShares = snapshot.docs.map((doc) => doc.data());
      setShares(updatedShares);
      const totalSharesAmount = await fetchAndCalculateTotalSharesAmount(
        user,
        updatedShares
      );
      setTotalShares(totalSharesAmount);
    });

    setIsLoading(false);

    // Unsubscribe when the component unmounts
    return () => {
      unsubscribeCashDeposits();
      unsubscribeBonds();
      unsubscribeTerms();
      unsubscribeIpos();
      unsubscribeShares();
    };
  }, []);

  // Assuming getCashDeposit, getStockFromUserDB, getUserIpos, and getBondsHoldings are async functions that fetch data from a database or an API.
  const fetchAndCalculateTotalCashDepositAmount = async (userId) => {
    try {
      // Fetch all necessary data
      const cashDepositData = await getCashDeposit(userId);
      const sharesData = await getStockFromUserDB(userId);
      const ipoData = await getUserIpos(userId);
      const bondData = await getBondsHoldings(userId);
      // Calculate total cash deposits amount including proceeds from sales
      const totalCashDeposits = await calculateTotalCashDepositsAmount(
        cashDepositData,
        sharesData,
        ipoData,
        bondData
      );

      return totalCashDeposits;
    } catch (error) {
      console.error("Error calculating total cash deposit amount:", error);
      return 0; // Return 0 or handle the error as appropriate
    }
  };

  // Fetch and calculate the total shares amount
  const fetchAndCalculateTotalSharesAmount = async (user, shares) => {
    const sharesData = await getStockFromUserDB(user, shares);
    return calculateTotalSharesAmount(sharesData);
  };

  // Fetch and calculate the total bond amount
  const fetchAndCalculateTotalBondAmount = async (user, bonds) => {
    const bondData = await getBondsHoldings(user, bonds);
    return calculateTotalBondAmount(bondData);
  };

  // Fetch and calculate the total term amount
  const fetchAndCalculateTotalTermAmount = async (user, terms) => {
    const termData = await getUserFixedTerm(user, terms); // Use your function to get term data
    return calculateTotalTermAmount(termData);
  };

  // Fetch and calculate the total IPO amount
  const fetchAndCalculateTotalIpoAmount = async (user, ipos) => {
    const ipoData = await getUserIpos(user, ipos); // Use your function to get IPO data
    return calculateTotalIpoAmount(ipoData);
  };

  // Calculate the total shares amount
  const calculateTotalSharesAmount = (shares) => {
    let totalAmount = 0;

    if (shares) {
      shares.forEach((share) => {
        if (share && share.type === "Buy") {
          totalAmount += convertToNumber(share.value);
        } else if (share && share.type === "Sell") {
          totalAmount -= convertToNumber(share.value);
        }
      });
    }

    return totalAmount;
  };

  // Revised function to include sales from shares, IPOs, bonds, and cash deposit withdrawals
  const calculateTotalCashDepositsAmount = async (
    cashDepositData = [],
    sharesData = [],
    ipoData = [],
    bondData = []
  ) => {
    // Ensure that each data set is an array, even if the input is null or undefined
    cashDepositData = cashDepositData || [];
    sharesData = sharesData || [];
    ipoData = ipoData || [];
    bondData = bondData || [];
    let totalCashDeposits = 0;

    // Add proceeds from cash deposits
    const addCashToDeposits = (data) => {
      data.forEach((item) => {
        if (
          item &&
          item.status &&
          item.status.trim().toUpperCase() === "CLEARED"
        ) {
          totalCashDeposits += convertToNumber(item.amount);
        } 
      });
    };

    // Add proceeds from selling IPOs
    const addIpoSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (item && item.type && item.type.trim().toUpperCase() === "SELL") {
          totalCashDeposits +=
            convertToNumber(item.numberOfShares) *
            convertToNumber(item.sharePrice);
        }
      });
    };

    // Add proceeds from selling shares
    const addSharesSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (item && item.type && item.type.trim().toUpperCase() === "SELL") {
          totalCashDeposits += convertToNumber(item.tradeAmount);
        }
      });
    };

    // Add proceeds from selling bonds
    const addBondSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (
          item &&
          item.typeOfRequest &&
          item.typeOfRequest.trim().toUpperCase() === "SELL"
        ) {
          totalCashDeposits += convertToNumber(item.currentValue);
        }
      });
    };

    // Apply sales addition for each financial instrument
    addCashToDeposits(cashDepositData);
    addSharesSalesToDeposits(sharesData);
    addIpoSalesToDeposits(ipoData);
    addBondSalesToDeposits(bondData);

    return totalCashDeposits;
  };

  // Calculate the total bond amount
  const calculateTotalBondAmount = (bonds) => {
    let totalAmount = 0;

    if (bonds) {
      bonds.forEach((bond) => {
        if (bond && bond.typeOfRequest === "buy") {
          totalAmount += convertToNumber(bond.currentValue);
        } else if (bond && bond.typeOfRequest === "sell") {
          totalAmount -= convertToNumber(bond.currentValue);
        }
      });
    }

    return totalAmount;
  };

  // Calculate the total term amount
  const calculateTotalTermAmount = (terms) => {
    let totalAmount = 0;

    if (terms) {
      terms.forEach((term) => {
        if (term && term.type === "deposit") {
          totalAmount += convertToNumber(term.principalAmount);
        } else if (term && term.type === "withdrawal") {
          totalAmount -= convertToNumber(term.principalAmount);
        }
      });
    }

    return totalAmount;
  };

  // Calculate the total IPO amount
  const calculateTotalIpoAmount = (ipos) => {
    let totalAmount = 0;

    if (Array.isArray(ipos) && ipos.length > 0) {
      ipos.forEach((ipo) => {
        const numberOfShares = convertToNumber(ipo.numberOfShares);
        const sharePrice = convertToNumber(ipo.sharePrice);

        if (ipo && ipo.type.trim().toUpperCase() === "INVEST") {
          totalAmount += numberOfShares * sharePrice;
        } else if (ipo && ipo.type.trim().toUpperCase() === "SELL") {
          totalAmount -= numberOfShares * sharePrice;
        }
      });
    }

    return totalAmount;
  };

  return (
    <div className="myAccounts_page">
      <div className="section_body">
        <div className="account_cards">
          <div className="account_card">
            <div className="card_icon">
              <CiMoneyBill size={60} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Cash Balance</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalDeposits)}
                </strong>
              )}
            </div>
          </div>

          <div className="account_card">
            <div className="card_icon">
              <BsCardChecklist size={50} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Bonds Account</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalBondAmount) || 0}
                </strong>
              )}
            </div>
          </div>
          <div className="account_card">
            <div className="card_icon">
              <CiMoneyCheck1 size={60} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Term Deposits</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalTermAmount) || 0}
                </strong>
              )}
            </div>
          </div>
          {isIposVisible && (
            <div className="account_card">
              <div className="card_icon">
                <PiBriefcase size={50} fill="#fff" />
              </div>
              <div className="card_texts">
                <h4 className="card_label">IPOs Balance</h4>
                {isLoading ? (
                  <div className="spinner"></div>
                ) : (
                  <strong className="card_amount">
                    $ {formatNumber(totalIpoAmount) || 0}
                  </strong>
                )}
              </div>
            </div>
          )}
          <div className="account_card">
            <div className="card_icon">
              <CiMoneyBill size={60} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Shares Account</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalShares)}
                </strong>
              )}
            </div>
          </div>
        </div>
        <section className="holdings_section">
          <div className="tab_header">
            <h2
              ref={bondsRef}
              className={`title ${activeTab === "bonds" ? "active" : ""}`}
              onClick={() => setActiveTab("bonds")}
            >
              My Bonds
            </h2>
            <h2
              ref={termsRef}
              className={`title ${activeTab === "terms" ? "active" : ""}`}
              onClick={() => setActiveTab("terms")}
            >
              My Term Deposit
            </h2>
            {isIposVisible && (
              <h2
                ref={iposRef}
                className={`title ${activeTab === "ipos" ? "active" : ""}`}
                onClick={() => setActiveTab("ipos")}
              >
                My IPOs
              </h2>
            )}
            <h2
              ref={stockRef}
              className={`title ${activeTab === "stock" ? "active" : ""}`}
              onClick={() => setActiveTab("stock")}
            >
              My Shares
            </h2>
            <div className="indicator" style={indicatorStyle}></div>
          </div>
          {activeTab === "bonds" && <BondsTable />}
          {activeTab === "terms" && <TermsTable />}
          {isIposVisible && activeTab === "ipos" && <IposTable />}
          {activeTab === "stock" && <StocksTable />}
        </section>
      </div>
    </div>
  );
}
