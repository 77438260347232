import React, { useEffect, useState } from "react";
import { AiOutlineStock } from "react-icons/ai";
import { BsBriefcase, BsCardChecklist } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CiLogout, CiMoneyCheck1 } from "react-icons/ci";
import { CgMenuGridR } from "react-icons/cg";
import { IoMdMenu } from "react-icons/io";
import {
  addLogNotification,
  displayBondsFeature,
  displayChatFeature,
  displayFixedTermFeature,
  displayIpoFeature,
  displayToolsFeature,
  formatNumber,
  getAuthUser,
  getBondsHoldings,
  getCashDeposit,
  getStockFromUserDB,
  getUserFixedTerm,
  getUserIpos,
} from "../../firebase/firestore";
import { PiBriefcase } from "react-icons/pi";
import Logo from "../../assets/white_logo.png";
import Logout from "../Modals/logout";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import "./style.css";

export default function Sidebar({ closeSidebar }) {
  const [totalBondAmount, setTotalBondAmount] = useState(0);
  const [totalTermAmount, setTotalTermAmount] = useState(0);
  const [totalIpoAmount, setTotalIpoAmount] = useState(0);
  const [totalSharesAmount, setTotalSharesAmount] = useState(0);
  const [totalCashDeposits, setTotalCashDeposits] = useState(0)
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [secondaryUser, setSecondaryUser] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isToolsVisible, setIsToolsVisible] = useState(false);
  const [isBondsVisible, setIsBondsVisible] = useState(false);
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isIposVisible, setIsIposVisible] = useState(false);

  // Get user's first name and secondary account holder name from secure storage
  useEffect(() => {
    const fetchUser = async () => {
      const storedFirstName = secureLocalStorage.getItem("user_firstName");
      const storedSecondaryHolderName = secureLocalStorage.getItem(
        "secondaryHolderName"
      );
      setIsLoading(true);

      if (storedFirstName) {
        const userName =
          storedFirstName.charAt(0).toUpperCase() + storedFirstName.slice(1);
        setUser({ firstName: userName });
      }
      if (storedSecondaryHolderName) {
        const secondaryHolderName =
          storedSecondaryHolderName.charAt(0).toUpperCase() +
          storedSecondaryHolderName.slice(1);
        setSecondaryUser({ secondaryHolderName: secondaryHolderName });
      }
      setIsLoading(false);
    };

    fetchUser();
  }, []);

  // Calculate total balance
  useEffect(() => {
    const totalBalance =
      totalCashDeposits +
      totalBondAmount +
      totalTermAmount +
      totalIpoAmount +
      totalSharesAmount;
    setBalance(totalBalance);
  }, [
    totalCashDeposits,
    totalBondAmount,
    totalTermAmount,
    totalIpoAmount,
    totalSharesAmount,
  ]);

  // Subscribe to  visibility changes
  useEffect(() => {
    const unsubscribe = displayChatFeature(setIsChatVisible);
    const unsubscribeTools = displayToolsFeature(setIsToolsVisible);
    const unsubscribeBonds = displayBondsFeature(setIsBondsVisible);
    const unsubscribeTerms = displayFixedTermFeature(setIsTermsVisible);
    const unsubscribeIpos = displayIpoFeature(setIsIposVisible);
    // Cleanup function
    return () => {
      unsubscribe();
      unsubscribeTools();
      unsubscribeBonds();
      unsubscribeTerms();
      unsubscribeIpos();
    };
  }, []);

  // Convert a string representation of a number to a JavaScript number
  function convertToNumber(stringAmount) {
    if (typeof stringAmount === "string") {
      return parseFloat(stringAmount.replace(",", ""));
    } else if (typeof stringAmount === "number") {
      return stringAmount;
    }
    return 0;
  }

  useEffect(() => {
    const user = getAuthUser();
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Fetch and calculate total cash deposit amount
    const fetchCashDeposits = async () => {
      const cashDepositData = await getCashDeposit(user);
      const sharesData = await getStockFromUserDB(user);
      const ipoData = await getUserIpos(user);
      const bondData = await getBondsHoldings(user);
      const totalCashDepositsAmount =
        await calculateTotalCashDepositsAmount(cashDepositData, sharesData, ipoData, bondData);
      setTotalCashDeposits(totalCashDepositsAmount);
    };

    // Fetch and calculate total bond amount
    const fetchBonds = async () => {
      const bonds = await getBondsHoldings(user);
      if (bonds) {
        const totalBondAmount = calculateTotalBondAmount(bonds);
        setTotalBondAmount(totalBondAmount);
      }
    };

    // Fetch and calculate total term amount
    const fetchTerms = async () => {
      const terms = await getUserFixedTerm(user);
      if (terms) {
        const totalTermAmount = calculateTotalTermAmount(terms);
        setTotalTermAmount(totalTermAmount);
      }
    };

    // Fetch and calculate total IPO amount
    const fetchIpos = async () => {
      const ipos = await getUserIpos(user);
      if (ipos) {
        const totalIpoAmount = calculateTotalIpoAmount(ipos);
        setTotalIpoAmount(totalIpoAmount);
      }
    };

    const fetchShares = async () => {
      const shares = await getStockFromUserDB(user);
      if (shares) {
        const totalSharesAmount = calculateTotalSharesAmount(shares);
        setTotalSharesAmount(totalSharesAmount);
      }
    };

    // Fetch data and calculate totals
    Promise.all([
      fetchCashDeposits(),
      fetchBonds(),
      fetchTerms(),
      fetchIpos(),
      fetchShares(),
    ]).then(() => {
      setIsLoading(false);
    });
  }, []);

  // Calculate the total shares amount
  const calculateTotalSharesAmount = (shares) => {
    let totalAmount = 0;

    if (shares) {
      shares.forEach((share) => {
        if (share && share.type === "Buy") {
          totalAmount += convertToNumber(share.value);
        } else if (share && share.type === "Sell") {
          totalAmount -= convertToNumber(share.value);
        }
      });
    }

    return totalAmount;
  };
  // Revised function to include sales from shares, IPOs, bonds, and cash deposit withdrawals
  const calculateTotalCashDepositsAmount = async (
    cashDepositData = [],
    sharesData = [],
    ipoData = [],
    bondData = []
  ) => {
    // Ensure that each data set is an array, even if the input is null or undefined
    cashDepositData = cashDepositData || [];
    sharesData = sharesData || [];
    ipoData = ipoData || [];
    bondData = bondData || [];
    let totalAmount = 0;

    // Add proceeds from cash deposits
    const addCashToDeposits = (data) => {
      data.forEach((item) => {
        if (
          item &&
          item.status &&
          item.status.trim().toUpperCase() === "CLEARED"
        ) {
          totalAmount += convertToNumber(item.amount);
        }
      });
    };

    // Add proceeds from selling IPOs
    const addIpoSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (item && item.type && item.type.trim().toUpperCase() === "SELL") {
          totalAmount +=
            convertToNumber(item.numberOfShares) *
            convertToNumber(item.sharePrice);
        }
      });
    };

    // Add proceeds from selling shares
    const addSharesSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (item && item.type && item.type.trim().toUpperCase() === "SELL") {
          totalAmount += convertToNumber(item.tradeAmount);
        }
      });
    };

    // Add proceeds from selling bonds
    const addBondSalesToDeposits = (data) => {
      data.forEach((item) => {
        if (
          item &&
          item.typeOfRequest &&
          item.typeOfRequest.trim().toUpperCase() === "SELL"
        ) {
          totalAmount += convertToNumber(item.currentValue);
        }
      });
    };

    // Apply sales addition for each financial instrument
    addCashToDeposits(cashDepositData);
    addSharesSalesToDeposits(sharesData);
    addIpoSalesToDeposits(ipoData);
    addBondSalesToDeposits(bondData);
    return totalAmount;
  };

  // Calculate the total bond amount
  const calculateTotalBondAmount = (bonds) => {
    let totalAmount = 0;

    if (bonds) {
      bonds.forEach((bond) => {
        if (bond && bond.typeOfRequest === "buy") {
          totalAmount += convertToNumber(bond.currentValue);
        } else if (bond && bond.typeOfRequest === "sell") {
          totalAmount -= convertToNumber(bond.currentValue);
        }
      });
    }
    return totalAmount;
  };

  // Calculate the total term amount
  const calculateTotalTermAmount = (terms) => {
    let totalAmount = 0;

    if (terms) {
      terms.forEach((term) => {
        if (term && term.type === "deposit") {
          totalAmount += convertToNumber(term.principalAmount);
        } else if (term && term.type === "withdrawal") {
          totalAmount -= convertToNumber(term.principalAmount);
        }
      });
    }

    return totalAmount;
  };

  // Calculate the total IPO amount
  const calculateTotalIpoAmount = (ipos) => {
    let totalAmount = 0;

    if (Array.isArray(ipos) && ipos.length > 0) {
      ipos.forEach((ipo) => {
        const numberOfShares = convertToNumber(ipo.numberOfShares);
        const sharePrice = convertToNumber(ipo.sharePrice);

        if (ipo && ipo.type.trim().toUpperCase() === "INVEST") {
          totalAmount += numberOfShares * sharePrice;
        } else if (ipo && ipo.type.trim().toUpperCase() === "SELL") {
          totalAmount -= numberOfShares * sharePrice;
        }
      });
    }
    return totalAmount;
  };

  // Logout function
  const handleLogout = async () => {
    setIsLoading(true);

    try {
      const user = auth.currentUser;

      if (user) {
        // Fetch the user's details from Firestore
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          // Update the user's 'isLoggedIn' field in Firestore to 'false'
          await updateDoc(userRef, { isLoggedIn: false });
          // const adminNotification = `User ${nameParts} with UID of ${user.uid} just logged out.`;
          await addLogNotification(userRef, user);
        }

        // Sign out the user
        await auth.signOut();
      }

      setIsLoading(false);
      navigate("/");
    } catch (error) {
      setIsLoading(false);
      console.error("Error signing out:", error);
    }
  };

  return (
    <aside className={`sidebar_component ${collapsed ? "collapsed" : ""}`}>
      <div className="logo_container">
        <img src={Logo} alt="logo" />
        <button
          onClick={() => setCollapsed(!collapsed)}
          className="menu_button"
        >
          <IoMdMenu />
        </button>
      </div>

      {/* dropdown */}
      <div className="menu_container">
        {/* Header */}
        <div className={`menu_header ${collapsed ? "collapsed" : ""}`}>
          <h3>{user?.firstName}</h3>
          <h3>{secondaryUser ? secondaryUser.secondaryHolderName : ""}</h3>
          <p style={{ display: "grid" }}>
            <span>Total Account Value: </span>
            <span> $ {formatNumber(balance)}</span>
          </p>
        </div>

        {/* Body */}
        <div className="menu_body">
          <ul className="menu_items">
            <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard"
                onClick={closeSidebar}
                title="Dashboard"
              >
                <CgMenuGridR className="menu_icon" size={16} />
                {!collapsed && "Dashboard"}
              </Link>
            </li>
            <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard/my-accounts"
                onClick={closeSidebar}
                title="My Accounts"
              >
                <BsBriefcase className="menu_icon" size={16} />
                {!collapsed && "My Accounts"}
              </Link>
            </li>
            {/* <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard/transactions"
                onClick={closeSidebar}
                title="Transactions"
              >
                <CiMoneyBill className="menu_icon" size={20} />
                {!collapsed && "Transactions"}
              </Link>
            </li> */}
            {/* <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard/stock-trading"
                onClick={closeSidebar}
                title="Stock Trading"
              >
                <AiOutlineStock className="menu_icon" size={20} />
                {!collapsed && "Stock Trading"}
              </Link>
             </li> */}

            {isBondsVisible && (
              <li className={`menu_list`}>
                <Link
                  className="menu_link"
                  to="/dashboard/bonds"
                  onClick={closeSidebar}
                  title="Bonds"
                >
                  <BsCardChecklist className="menu_icon" size={18} />
                  {!collapsed && "Bonds"}
                </Link>
              </li>
            )}
            {isTermsVisible && (
              <li className={`menu_list`}>
                <Link
                  className="menu_link"
                  to="/dashboard/fixed-term-deposits"
                  onClick={closeSidebar}
                  title="Fixed Term Deposits"
                >
                  <CiMoneyCheck1 size={20} className="menu_icon" />
                  {!collapsed && "Fixed Term Deposits"}
                </Link>
              </li>
            )}
            {isIposVisible && (
              <li className={`menu_list`}>
                <Link
                  className="menu_link"
                  to="/dashboard/ipos"
                  onClick={closeSidebar}
                  title="IPOs"
                >
                  <PiBriefcase className="menu_icon" size={20} />
                  {!collapsed && "IPOs"}
                </Link>
              </li>
            )}
            {isToolsVisible && (
              <li className={`menu_list`}>
                <Link
                  className="menu_link"
                  to="/dashboard/market-analysis"
                  onClick={closeSidebar}
                  title="Market Analysis"
                >
                  <AiOutlineStock className="menu_icon" size={20} />
                  {!collapsed && "Market Analysis"}
                </Link>
              </li>
              // <li
              //   className={`menu_list ${toolsDropdownOpen ? "open" : ""}`}
              //   title="Tools"
              // >
              //   <div onClick={toggleToolsDropdown} className="menu_link">
              //     <AiOutlineStock className="menu_icon" size={18} />
              //     {!collapsed && "Tools"}
              //   </div>
              //   {toolsDropdownOpen && (
              //     <ul className="dropdown_menu">
              //       <li title="Market Analysis">
              //         <Link
              //           className="dropdown_link"
              //           to="/dashboard/tools/market-analysis"
              //           onClick={closeSidebar}
              //         >
              //           <TbBrandGoogleAnalytics
              //             className="menu_icon"
              //             size={18}
              //           />
              //           {!collapsed && "Market Analysis"}
              //         </Link>
              //       </li>
              //     </ul>
              //   )}
              // </li>
            )}
            <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard/account-details"
                onClick={closeSidebar}
                title="Account Details"
              >
                <IoPersonOutline className="menu_icon" size={18} />
                {!collapsed && "Account Details"}
              </Link>
            </li>
            {/* {isChatVisible && (
              <li className={`menu_list`}>
                <Link
                  className="menu_link"
                  to="/dashboard/chat"
                  onClick={closeSidebar}
                  title="Chat With Us"
                >
                  <BsChatLeftText className="menu_icon" size={18} />
                  {!collapsed && "Chat With Us"}
                </Link>
              </li>
            )} */}
            {/* <li className={`menu_list`}>
              <Link className="menu_link" to="/dashboard/interest-calculator" onClick={closeSidebar}>
                <AiOutlineCalculator className="menu_icon" size={18} />
                <span>Interest Calculator</span>
              </Link>
            </li> */}
            <li className={`menu_list`}>
              <Link
                className="menu_link"
                to="/dashboard/notifications"
                onClick={closeSidebar}
                title="Notifications"
              >
                <IoMdNotificationsOutline className="menu_icon" />
                {!collapsed && "Notifications"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Logout */}
      <div className="menu_logout">
        <div className="menu_body">
          <div
            className="menu_list"
            onClick={() => {
              setIsLogoutModalOpen(true);
              closeSidebar();
            }}
            title="Logout"
          >
            <CiLogout className="menu_icon" />
            {!collapsed && "Logout"}
            <Logout
              isOpen={isLogoutModalOpen}
              isLoading={isLoading}
              onLogout={() => {
                setIsLoading(true);
                handleLogout();
                auth
                  .signOut()
                  .then(() => {
                    setIsLoading(false);
                    navigate("/");
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    console.error("Error signing out:", error);
                  });
              }}
              onClose={() => setIsLogoutModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}
