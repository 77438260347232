// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3zC304jJnFhkCovwlKV_ZxH9I0tpl0Ns",
  authDomain: "avf-investment-management.firebaseapp.com",
  projectId: "avf-investment-management",
  storageBucket: "avf-investment-management.appspot.com",
  messagingSenderId: "692499558591",
  appId: "1:692499558591:web:d003e71cfb557d1fb6960a",
  measurementId: "G-HYE4DEL85E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app); 
export const storage = getStorage(app);


