import React, { useEffect, useState } from "react";
import LoadingScreen from "../LoadingScreen";
import {
  depositFixedTerm,
  formatNumber,
  getAuthUser,
  getCurrentDate,
  getFixedTerm,
} from "../../firebase/firestore";
import "./style.css";
import FixedDepositModal from "../Modals/fixedDepositModal";

export default function FixedTermDeposits() {
  const [fixedTerm, setFixedTerm] = useState([]);
  const [fixedDepositModalOpen, setFixedDepositModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFixedTerm, setSelectedFixedTerm] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    const fetchFixedTerm = async () => {
      const user = getAuthUser();

      if (user) {
        const fetchedFixedTerm = await getFixedTerm(user);
        if (fetchedFixedTerm) {
          setFixedTerm(fetchedFixedTerm);
        }
      } else {
        console.error("No user is currently authenticated.");
      }
      setIsLoading(false);
    };

    fetchFixedTerm();
  }, []);

  const handleDeposit = async (amount) => {
    setIsLoading(true);
    try {
      const amountAsNumber = parseFloat(amount);

      // Check if there is a selectedFixedTerm before proceeding
      if (!selectedFixedTerm) {
        console.error("No selected fixed term deposit.");
        return;
      }

      const newDeposit = {
        date: getCurrentDate(),
        amount: amountAsNumber.toFixed(2),
        status: "Pending",
        bankName: selectedFixedTerm.bankName,
        term: selectedFixedTerm.term,
        interestRate: selectedFixedTerm.interestRate,
        type: "deposit",
      };
      const uid = getAuthUser();
      await depositFixedTerm(uid, newDeposit);
    } catch (error) {
      console.error("Error adding deposit transaction: ", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="fixedTerm_page">
      <div className="section_header">
        <h2>Fixed Term Deposits</h2>
        <span>
          Please click the term deposit you are interested in and enter the
          amount you wish to deposit.
        </span>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      <div className="body">
        {fixedTerm.length === 0 && <h5>No Fixed Term Deposits available.</h5>}
        {isLoading ? (
          <LoadingScreen />
        ) : (
          fixedTerm.map((item, index) => (
            <div
              key={index}
              className="portfolio_card"
              onClick={() => {
                setSelectedFixedTerm(item);
                setFixedDepositModalOpen(true);
              }}
            >
              <div className="portfolio">
                <div className="portfolio_col">
                  <div className="portfolio_img">
                    <img src={item.imagePreview} alt={item.bankName} />
                  </div>
                  <div className="col_details">
                    <div className="issuer_dets">
                      <p className="name"> {item.bankName} </p>
                      <div className="type"></div>
                    </div>
                    <div className="more_dets">
                      <div className="maturity_row">
                        <p className="bold_text">Term:</p>
                        <span className="reg_text"> {item.term} </span>
                      </div>

                      <div className="maturity_row">
                        <p className="bold_text">Minimum Amount:</p>
                        <span className="reg_text">$ {formatNumber(item.minAmount)} </span>
                      </div>
                    </div>
                  </div>
                  <div className="coupon_dets">
                    <div className="coupon_wrap">
                      <div className="coupon_percent">
                        <span className="number"> {item.interestRate} </span>
                        <span className="percent">%</span>
                      </div>
                      <span className="reg_text">Interest Rate</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {fixedDepositModalOpen && (
        <FixedDepositModal
          fixedTerm={selectedFixedTerm}
          onClose={() => {
            setFixedDepositModalOpen(false);
            setSelectedFixedTerm(null);
          }}
          onDeposit={handleDeposit}
          isOpen={() => setFixedDepositModalOpen(true)}
        />
      )}
    </div>
  );
}
