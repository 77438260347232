import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';
import logo from '../../../assets/white_logo.png';
import { fetchPasswordPolicySetting } from '../../../firebase/firestore';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import './style.css';

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isStrongPasswordPolicy, setIsStrongPasswordPolicy] = useState(true); 
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (pass, isStrongPolicy) => {
    if (isStrongPolicy) {
      const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
      return regex.test(pass);
    } else {
      return pass.length >= 6;
    }
  };

  const setErrorWithTimeout = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(""), 4000);
  };

  const setMessageWithTimeout = (successMessage) => {
    setMessage(successMessage);
    setTimeout(() => setMessage(""), 4000);
  };

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setErrorWithTimeout('New password and confirm password do not match.');
      return false;
    }
    if (!validatePassword(newPassword, isStrongPasswordPolicy)) {
      setErrorWithTimeout(
        isStrongPasswordPolicy
          ? 'Password must be at least 8 characters long, must contain at least one number and a special character.'
          : 'Password must be at least 6 digits long.'
      );
      return false;
    }
    return true;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validatePasswords()) {
      setIsLoading(false);
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setMessageWithTimeout("Password updated successfully.");
      navigate('/');
    } catch (err) {
      setErrorWithTimeout(err.message || "Error updating password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Fetch the password policy setting from Firestore.
    fetchPasswordPolicySetting()
      .then((isStrong) => {
        setIsStrongPasswordPolicy(isStrong);
      })
      .catch((error) => {
        console.error('Error fetching password policy:', error);
      });
  }, []);

  return (
    <section className="changePassword_page">
      <div className="changePassword_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Change Password</h1>
          <p className="subtitle">Please enter your current and new passwords.</p>
          <span
            className="help_icon"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            ?
            {showTooltip && (
              <div className="tooltip">
                <strong>Password Requirements:</strong>
                <ul>
                  <li>At least 8 characters long</li>
                  <li>One uppercase letter</li>
                  <li>One lowercase letter</li>
                  <li>One number</li>
                  <li>One special character (!@#$%^&*)</li>
                </ul>
              </div>
            )}
          </span>
        </div>
        <form className="form_wrap" onSubmit={handleChangePassword}>
           <div className="password_field">
           <input
             type={showPassword ? "text" : "password"}
            name="currentPassword"
            placeholder="Current Password"
            className="pass_field"
            value={currentPassword}
            onChange={({ target: { value } }) => setCurrentPassword(value)}
            required
            disabled={isLoading}
          />
            {showPassword ? (
              <IoEye
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <IoEyeOff
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
           <div className="password_field">
           <input
            type={showPassword ? "text" : "password"}
            name="newPassword"
            placeholder="New Password"
            className="pass_field"
            value={newPassword}
            onChange={({ target: { value } }) => setNewPassword(value)}
            required
            disabled={isLoading}
          />
            {showPassword ? (
              <IoEye
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            ) : (
              <IoEyeOff
                className="password_icon"
                onClick={togglePasswordVisibility}
              />
            )}
          </div>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm New Password"
            className="input_field"
            value={confirmPassword}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
            required
            disabled={isLoading}
          />
          {isLoading ? (
            <button className="change_btn" type="submit" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="change_btn" type="submit">Change Password</button>
          )}
          <div className="info">
            {message && <p className="success_msg">{message}</p>}
            {error && <p className="error_msg">{error}</p>}
          </div>
        </form>
      </div>
    </section>
  );
}
